import { Controller } from "@hotwired/stimulus";
import { create } from "apisauce";

// Connects to data-controller="kyc-pass-tap"
export default class extends Controller {
  static values = {
    environment: String
  };

  connect() {
    console.log("Connected to kyc-pass-tap controller!");
  }

  handleTap() {
    console.log("Handeling Tap");
    console.log(this.environmentValue);

    const environment = this.environmentValue;
    const baseUrlPoc = environment === "development" ? "http://127.0.0.1:3001" : "https://fan-id-poc.passentry.com/";
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const tableBody = document.querySelector("#table-body");
    const simulateBtn = document.querySelector(".simulate-btn");

    simulateBtn.addEventListener("click", function () {
      simulateBtn.style.display = "none";
    });

    const proxyRequestBase = create({
      baseURL: `${baseUrlPoc}`,
      headers: {
        "x-csrf-token": csrfToken
      }
    });

    const proxyRequest = async () => {
      try {
        const response = await proxyRequestBase.get("/kyc/fetch_transaction_details");
        if (response.ok) {
          console.log(`Transaction details retrieval successful!`);

          const passTapData = response.data;

          const currentTime = new Date();
          const formattedDate = currentTime.toLocaleDateString("en-US", { day: "2-digit", month: "short" });
          const formattedTime = currentTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });

          const formatedDateTime = `${formattedDate} ${formattedTime}`;

          const min = 1000;
          const max = 9999;
          const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

          const age = this.calculateAge(passTapData.dateOfBirth);

          const tableRow = `
            <h3>Tap ID</h3>
            <p>${randomNum}</p>
            <h3>First Name</h3>
            <p>${passTapData.firstName}</p>
            <h3>Last Name</h3>
            <p>${passTapData.lastName}</p>
            <div style="display: flex; justify-content: space-around">
              <div>
                <h3>DoB</h3>
                <p>${passTapData.dateOfBirth}</p>
              </div>
              <div>
                <h3>Age</h3>
                <p>${age}</p>
              </div>
            </div>
            <h3>ID Number</h3>
            <p style="filter: blur(3px);">${passTapData.documentNumber}</p>
            <h3>KYC Status</h3>
            <p class="verified">${passTapData.decision}</p>
            <h3>Tapped on</h3>
            <p>${formatedDateTime}</p>
            </br>
            <p><a href="/kyc/id_details_demo">Details</a></p>
            `;
          tableBody.insertAdjacentHTML("afterbegin", tableRow);
        } else {
          console.error("Request failed:", response.problem);
          return null;
        }
      } catch (error) {
        console.error("Request error:", error);
        return null;
      }
    };
    proxyRequest();
  }

  calculateAge(dateOfBirth) {
    const birthdate = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - birthdate.getFullYear();

    const hasBirthdayPassed =
      today.getMonth() > birthdate.getMonth() ||
      (today.getMonth() === birthdate.getMonth() && today.getDate() >= birthdate.getDate());

    if (!hasBirthdayPassed) {
      age--;
    }

    return age;
  }
}
