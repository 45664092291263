import { Controller } from "@hotwired/stimulus";
import { create } from "apisauce";

export default class extends Controller {
  static values = {
    environment: String
  };

  connect() {
    console.log("Connected to kyc-results controller test!");

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const accountID = urlParams.get("accountId");
    const transactionStatus = urlParams.get("acquisitionStatus");
    const workFlowID = urlParams.get("workflowExecutionId");

    const transactionResultsContainer = document.getElementById("transaction-results");
    const transactionDetailsContainer = document.getElementById("transaction-details");

    document.addEventListener("DOMContentLoaded", function () {
      const resultsData = `<h3>Transaction Status</h3>
      <p>${transactionStatus}</p>
      <h3>Account ID</h3>
      <p>${accountID}</p>
      <h3>Work Flow Execution ID</h3>
      <p>${workFlowID}</p>`;

      transactionResultsContainer.innerHTML = resultsData;
    });

    const environment = this.environmentValue;
    const baseUrlPoc = environment === "development" ? "http://127.0.0.1:3001" : "https://fan-id-poc.passentry.com/";
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    const sendTransactionDetailsHead = create({
      baseURL: `${baseUrlPoc}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": csrfToken
      }
    });

    const getTransactionDetails = async () => {
      try {
        const requestBody = {
          accountID: accountID,
          transactionStatus: transactionStatus,
          workFlowExecutionID: workFlowID
        };

        const response = await sendTransactionDetailsHead.post("/kyc/success", requestBody);
        if (response.ok) {
          console.log(`Transaction details retrieval successful!`);
          return JSON.stringify(response.data);
        } else {
          console.error("Request failed:", response.problem);
          return null;
        }
      } catch (error) {
        console.error("Request error:", error);
        return null;
      }
    };

    getTransactionDetails()
      .then((responseData) => {
        const passResponseData = JSON.parse(responseData);

        const loadingIndicator = document.getElementById("loading-indicator");
        loadingIndicator.style.display = "none";

        const passData = `
              <h3>Name</h3>
              <p>${passResponseData.firstName} ${passResponseData.lastName}</p>
              <div style="display: flex; justify-content: space-between">
                <div>
                  <h3>DOB</h3>
                  <p>${passResponseData.dateOfBirth}</p>
                </div>
                <div>
                  <h3>ID Number</h3>
                  <p>${passResponseData.documentNumber}</p>
                </div>
                <div>
                  <h3>KYC</h3>
                  <p>${passResponseData.decision}</p>
                </div>
              </div>
              `;

        transactionDetailsContainer.innerHTML = passData;

        const getPassButtonContainer = document.getElementById("button-container");

        const linkButton = document.createElement("button");
        linkButton.textContent = "Get your pass";
        linkButton.classList.add("get-pass-button");

        getPassButtonContainer.appendChild(linkButton);

        const getPassButton = document.querySelector(".get-pass-button");

        getPassButton.addEventListener("click", () => {
          this.createPass(passResponseData);
        });
      })
      .catch((error) => {
        console.error("Promise error:", error);
      });
  }
  createPass(data) {
    let passData = JSON.stringify({
      pass: {
        fanId: `${Math.floor(10000000 + Math.random() * 90000000).toString()}`,
        firstName: `${data.firstName}`,
        lastName: `${data.lastName}`,
        dob: `${data.dateOfBirth}`,
        idNumber: `${data.documentNumber}`,
        // identification: `${data.decision}`,
        // instagram: "@singeasywestend",
        stagefrontInfo: "https://www.stagefronttickets.com/",
        // phone: "020 7278 1966",
        nfc: true
      }
    });
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    const environment = this.environmentValue;
    const baseUrlPoc = environment === "development" ? "http://127.0.0.1:3001" : "https://fan-id-poc.passentry.com/";

    const createPassBaseConn = create({
      baseURL: baseUrlPoc,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": csrfToken
      }
    });

    const createPassRequest = async (passData) => {
      try {
        const requestBody = {
          data: passData
        };
        const response = await createPassBaseConn.post("/kyc/create_pass", requestBody);
        if (response.ok) {
          location.href = response.data["data"]["attributes"]["downloadUrl"];
        } else {
          console.error("Creating pass failed:", response.problem);
          return null;
        }
      } catch (error) {
        console.error("Creating pass request error:", error);
        return null;
      }
    };
    createPassRequest(passData);
  }
}
