import { Controller } from "@hotwired/stimulus";
import { create } from "apisauce";

export default class extends Controller {
  static targets = ["form", "input"];
  static values = {
    environment: String
  };

  workFlowSubmit(event) {
    event.preventDefault();

    const workFlowID = this.inputTarget.value;
    const environment = this.environmentValue;
    const baseUrlJumioAuth = "https://auth.emea-1.jumio.ai";
    const baseUrlPoc = environment === "development" ? "http://127.0.0.1:3001" : "https://fan-id-poc.passentry.com/";
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    const proxyRequestBase = create({
      baseURL: `${baseUrlPoc}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": csrfToken
      }
    });

    const proxyRequest = async () => {
      try {
        const requestBody = {
          customerInternalReference: "PassEntry",
          workflowDefinition: {
            key: workFlowID
          }
        };

        const response = await proxyRequestBase.post("/kyc/proxy", requestBody);
        if (response.ok) {
          location.href = response.data;
        } else {
          console.error("PUT request failed:", response.problem);
          return null;
        }
      } catch (error) {
        console.error("PUT request error:", error);
        return null;
      }
    };
    proxyRequest();
  }
}
